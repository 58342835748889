<template>
  <div class="popup_wrap" style="width:700px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL010P080.001') }}</h1>
      <div class="content_box mt10">
        <div id="realgrid" style="width: 100%; height: 600px" />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'cstEnm', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstPrflNo', dataType: 'text' },
  { fieldName: 'cstPrflNm', dataType: 'text' }
]

const columns = [
  { name: 'cstNo', fieldName: 'cstNo', header: { text: app.$t('cp.CTRL010P080.002') }, editable: false, width: 50 },
  { name: 'cstEnm', fieldName: 'cstEnm', header: { text: app.$t('cp.CTRL010P080.003') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'cstPrflNm', fieldName: 'cstPrflNm', header: { text: app.$t('cp.CTRL010P080.004') }, editable: false, width: 80, styleName: 'text_left' }
]

export default {
  name: 'ProfileListPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgShipper: [],
          profile: []
        }
      }
    }
  },
  data: function () {
    return {
      list: [],
      chkHistory: []
    }
  },
  mounted () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 35
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
    gridView.footers.visible = false
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    gridView.onItemAllChecked = (_grid, checked) => {
      const lastProfile = gridView.getJsonRows().map(row => row.cstPrflNo).at(-1)
      if (checked) {
        $vm.chkHistory.push(lastProfile)
      } else {
        $vm.chkHistory = []
      }
    }

    gridView.onItemChecked = (_grid, itemIndex, checked) => {
      const lastProfile = gridView.getValue(itemIndex, 'cstPrflNo')
      if (checked) {
        $vm.chkHistory.push(lastProfile)
      } else if ($vm.chkHistory.at(-1) === lastCntrSzTyp) {
        $vm.chkHistory.pop()
      }
    }

    $vm.searchData()
  },
  methods: {
    searchData () {
      cpCommonInfo.profileList(this.parentInfo.bkgShipper.join(',')).then(response => {
        this.list = response.data
        provider.setRows(this.list)
        this.checkedItems()
      }).catch(err => {
        console.log(err)
      })
    },
    checkedItems () {
      if (this.parentInfo.profile.length !== 0) {
        // input 내용 체크 반영
        const prflList = this.parentInfo.profile
        const prflIdxList = []
        prflList.forEach((item, idx) => {
          provider.getJsonRows(0, -1).forEach((row, idx2) => {
            if (item === row.cstPrflNo) {
              prflIdxList.push(idx2)
            }
          })
        })
        for (const i of prflIdxList) {
          gridView.checkItem(i, true)
        }

        // 체크 항목 상단 배치
        const rows = []
        for (const row of gridView.getCheckedRows()) {
          rows.push(provider.getRows(row, row)[0])
        }
        provider.removeRows(gridView.getCheckedRows())
        for (let i = rows.length - 1; i > -1; i--) {
          const values = rows[i]
          provider.insertRow(0, {
            cstNo: values[0],
            cstEnm: values[1],
            cstNm: values[2],
            cstPrflNo: values[3],
            cstPrflNm: values[4]
          })
        }
        for (const i in rows) {
          gridView.checkItem(i, true)
        }
      }
    },
    layerClose () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    select () {
      const profile = gridView.getCheckedRows().map((row) => provider.getValue(row, 'cstPrflNo'))
      const profileLength = profile.length
      const lastProfile = !['', undefined].includes(this.chkHistory.at(-1)) ? this.chkHistory.at(-1) : profile.at(-1)

      this.$emit('close', { profile: profile, profileLength: profileLength, lastProfile: lastProfile })
      this.layerClose()
    }
  }
}
</script>
